export default class ApiCargaService {
  constructor(http) {
    this._http = http
  }

  async importarArquivo(parametros, arquivo) {
    let formData = new FormData()
    formData.append('arquivo', arquivo)
    formData.append('parametros', JSON.stringify(parametros))

    const { data } = await this._http.post(`/api/api-carga/upload`, formData)
    return data
  }

  async getListaArquivosPorConsignataria(consignatariaId) {
    const { data } = await this._http.get(
      `/api/api-carga/consignataria/${consignatariaId}`,
    )
    return data
  }

  async getListaArquivos(tiposArquivos) {
    const { data } = await this._http.get(`/api/api-carga/${tiposArquivos}`)
    return data
  }

  async abrirConexaoApiCargasViaSocket() {
    const { data } = await this._http.post(
      `/api/api-carga/abrir-conexao-api-cargas-via-socket`,
    )
    return data
  }

  async fecharConexaoApiCargasViaSocket(arquivo) {
    const { data } = await this._http.post(
      `/api/api-carga/fechar-conexao-api-cargas-via-socket`,
      arquivo,
    )
    return data
  }

  async solicitarProcessamento(arquivo) {
    const { data } = await this._http.post(
      `/api/api-carga/solicitar-processamento`,
      arquivo,
    )
    return data
  }

  async baixarArquivoCartao(nomeArquivo) {
    const headers = { 'Content-Type': 'text/plain' }

    return this._http.get(`/api/api-carga/baixarArquivoCartao/${nomeArquivo}`, {
      headers: headers,
      responseType: 'blob',
    })
  }

  async baixarArquivo(nomeArquivo) {
    const headers = { 'Content-Type': 'text/plain' }

    return this._http.get(`/api/api-carga/baixarArquivo/${nomeArquivo}`, {
      headers: headers,
      responseType: 'blob',
    })
  }

  async baixarRelatorioCritica(data) {
    let formData = new FormData()
    formData.append('lote', JSON.stringify(data))

    return this._http.post(`/api/api-carga/baixarRelatorioCritica`, formData)
  }

  async gerarMovimento(parametros) {
    const { data } = await this._http.post(
      `/api/api-carga/gerar-movimento`,
      parametros,
    )
    return data
  }
}
